<template>
	<router-view />
</template>

<script>
import SwiperCore, { Navigation, Pagination } from 'swiper'
SwiperCore.use([Navigation, Pagination])

export default {}
</script>

<style lang="scss">
@font-face {
	font-family: 'Montserrat';
	src: url('~@/assets/fonts/Montserrat-Medium.ttf') format('TrueType');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'Montserrat';
	src: url('~@/assets/fonts/Montserrat-Regular.ttf') format('TrueType');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'Montserrat';
	src: url('~@/assets/fonts/Montserrat-SemiBold.ttf') format('TrueType');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'RF Dewi Expanded';
	src: url('~@/assets/fonts/RFDewiExpanded-Black.ttf') format('TrueType');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}
html {
	line-height: 1.15;
	-webkit-text-size-adjust: 100%;
}
body {
	margin: 0;
}

#special {
	margin-top: 70px;
}
</style>
